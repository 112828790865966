import React, {Component} from 'react'
import {fetchAdvisoryBoards} from './../helpers/Api'
import {Link} from "react-router-dom";
import AuthService from '../login/Auth'
import TopNav from "../helpers/TopNav";


export const auth = new AuthService();

class AdvisoryBoardsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "Loading",
            advisoryBoardData: null,
        };
    }

    componentDidMount() {
        fetchAdvisoryBoards(auth.getToken())
            .then(data => {
                this.setState({status: "Fetch completed", advisoryBoardData: data})
            }).catch(_ => this.setState({status: "Error"}))
    }

    renderLoading() {
        switch (this.state.status) {
            case "Loading":
                return <p> Fetching, please wait </p>;
            case "Error":
                return <p> An error occurred fetching AdvisoryBoards. Please try again later. </p>;
            default:
                return null
        }
    };

    render() {
        return (
            <div>
                <TopNav token={auth.getToken()} title="Advisory Board"/>
                {this.state.advisoryBoardData &&
                <div className="cards">
                    {this.state.advisoryBoardData.map((advisoryBoard, index) =>
                        <Link to={`/advisory_board/${advisoryBoard.id}`} className="card" key={index}>
                            <div className="card-body">
                                <h6 className="card-title">{advisoryBoard.name}</h6>
                            </div>
                        </Link>
                    )}
                </div>
                }
                {this.state.advisoryBoardData && this.state.advisoryBoardData.length === 0 &&
                <p> You have no Advisory Boards. Please contact us if you'd like to set one up.</p>
                }
                {this.renderLoading()}
            </div>
        )
    }
}

export default AdvisoryBoardsList


