import React, {Component} from 'react'
import {
    fetchParticipant
} from './../helpers/Api'
import AuthService from "../login/Auth";
import './Participant.css';


export const auth = new AuthService();

class Participant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "Idle",
            participantData: null,
            id: Number(this.props.match.params.id)
        };
    }

    componentDidMount() {
        this.setState({status: "Loading"});

        fetchParticipant(auth.getToken(), this.state.id)
            .then(data => {
                this.setState({status: "Fetch completed", participantData: data})
            }).catch(_ => this.setState({status: "Error"}))
    }

    renderLoading() {
        switch (this.state.status) {
            case "Loading":
                return <p> Fetching, please wait </p>;
            case "Error":
                return <p> An error occurred fetching the Participant. Please try again later. </p>;
            default:
                return null
        }
    };

    render() {
        return (
            <div>
                {this.state.participantData &&
                <div>
                    <div className="plane">
                        <div className="title-area">
                            <h3 className="title">{this.state.participantData.name}</h3>
                        </div>
                        <div className="singal-modal-content column">
                            <h6 className="header">Contact Information</h6>
                            <div className="flex summary-title half-margin">Email</div>
                            <div className="flex summary-item">
                                {this.state.participantData.email}
                            </div>

                            <div className="flex summary-title half-margin">Phone Number</div>
                            <div className="flex summary-item">
                                {this.state.participantData.phone}
                            </div>

                            <div className="flex summary-title half-margin">Neighborhood</div>
                            <div className="flex summary-item">
                                {this.state.participantData.neighborhood}
                            </div>
                        </div>
                    </div>
                </div>
                }
                {this.renderLoading()}
            </div>
        )
    }
}

export default Participant


