import React, {Component} from 'react'
import {
    fetchAdvisoryBoard
} from './../helpers/Api'
import AuthService from "../login/Auth";
import './AdvisoryBoard.css';
import TopNav from "../helpers/TopNav";
import {Link} from "react-router-dom";
import styled from 'styled-components'
import {getInitials} from "../helpers/StringHelpers";

const RoundedButton = styled(Link)`
    background: #397FCC;
    color: white;
    padding: .75em 1em;
    border-radius: 1.5em;
    -webkit-box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.35);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    max-width: 15em;
    margin-right: 1em;
    text-align: center;
    &:hover {
        color: white;
        background: #6FA2DA;
        -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.35);
        -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.35);
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.35);
    }
`;

export const auth = new AuthService();

class AdvisoryBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "Idle",
            advisoryBoardData: null,
            id: Number(this.props.match.params.id)
        };
    }

    componentDidMount() {
        this.setState({status: "Loading"});

        fetchAdvisoryBoard(auth.getToken(), this.state.id)
            .then(data => {
                this.setState({status: "Fetch completed", advisoryBoardData: data})
            }).catch(_ => this.setState({status: "Error"}))
    }

    renderLoading() {
        switch (this.state.status) {
            case "Loading":
                return <p> Fetching, please wait </p>;
            case "Error":
                return <p> An error occurred fetching the AdvisoryBoard. Please try again later. </p>;
            default:
                return null
        }
    };


    render() {
        return (
            <div>
                <TopNav token={auth.getToken()} title="Advisory Board"/>
                {this.state.advisoryBoardData &&
                <div>
                    <h6 className="header">{this.state.advisoryBoardData.name}</h6>
                    {this.state.advisoryBoardData.conversations &&
                    <div className="flex-row-container">
                        {this.state.advisoryBoardData.conversations.map((conversation, _) =>
                            <RoundedButton to={`/conversations/${conversation.id}`}
                                           className="margin-bottom">
                                {conversation.title}
                            </RoundedButton>
                        )}
                    </div>
                    }
                    {this.state.advisoryBoardData.participants &&
                    <div className="flex-row-container">
                        {this.state.advisoryBoardData.participants.map((person, _) =>
                            <Link to={`/participants/${person.id}`}>
                                <div id="participant-box" className="flex-row-item dashboard-box">
                                    <div
                                        className="large-bubble margin-bottom">{getInitials(person.name)}</div>
                                    <div className="margin-bottom">{person.name}</div>
                                </div>
                            </Link>
                        )}
                    </div>
                    }
                </div>
                }
                {this.renderLoading()}
            </div>
        )
    }
}

export default AdvisoryBoard


