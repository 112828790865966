import React, {Component} from 'react'
import './TopNav.css'
import {auth} from '../App'


class TopNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            client_name: ""
        }
    }

    componentDidMount() {
        this.setState({client_name: auth.getClientName()});
    }

    render() {
        return (
            <nav className="navbar navbar-expand-lg">
                <span className="nav-header">{this.props.title}</span>
                <span>{this.state.client_name}</span>
            </nav>
        )
    }
}

export default TopNav
