import React, {Component} from 'react'
import {
    fetchEmails
} from './../helpers/Api'
import Moment from 'react-moment';
import AuthService from "../login/Auth";
import styled from 'styled-components'


export const auth = new AuthService();


class Inbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "Idle",
            emailData: null,
            selectedEmail: null
        };

        this.setEmail = this.setEmail.bind(this)
    }

    componentDidMount() {
        this.setState({status: "Loading"});

        fetchEmails(auth.getToken())
            .then(data => {
                this.setState({emailData: data.emails, status: "Success"});
                if (data.emails.length > 0) {
                    this.setState({selectedEmail: data.emails[0]})
                }
            }).catch(_ => this.setState({status: "Error"}));
    }

    setEmail(email) {
        this.setState({selectedEmail: email});
    }

    renderLoading() {
        switch (this.state.status) {
            case "Loading":
                return <p> Fetching, please wait </p>;
            case "Error":
                return <p> An error occurred fetching the Conversation. Please try again later. </p>;
            default:
                return null
        }
    };

    render() {
        return (
            <div>
                {this.state.emailData &&
                <div>
                    <div className="singal-modal-content">
                        <div className="flex-1">
                            {this.state.emailData.map((email, i) =>
                                <div onClick={() => this.setEmail(email)}>
                                    <EmailBox>
                                        <div className="box">
                                            <div className="flex">
                                                <BoldText className="flex-2">{email.subject}</BoldText>
                                                <Moment className="flex-1" format="MMMM DD">
                                                    {email.sent}
                                                </Moment>
                                            </div>
                                            <OverFlowControlled>
                                                {email.body}
                                            </OverFlowControlled>
                                        </div>
                                    </EmailBox>
                                    {i + 1 !== this.state.emailData.length &&
                                    <Line/>
                                    }
                                </div>
                            )}

                        </div>
                        <div className="flex-2">
                            {this.state.selectedEmail &&
                            <EmailBox>
                                <div className="flex">
                                    <GoldText className="flex-1">To: {this.state.selectedEmail.to}</GoldText>
                                    <Moment className="flex-1" format="LLLL">
                                        {this.state.selectedEmail.sent}
                                    </Moment>
                                </div>
                                <div>
                                    {this.state.selectedEmail.body}
                                </div>
                            </EmailBox>
                            }

                            {!this.state.selectedEmail &&
                            <EmailBox>
                                No emails sent
                            </EmailBox>
                            }
                        </div>
                    </div>
                </div>
                }
                {this.renderLoading()}
            </div>
        )
    }
}

const EmailBox = styled.div`
    background: white;
    padding: 1em;
    margin-right: 1em;
`;

const OverFlowControlled = styled.div`
    max-width: 16em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const BoldText = styled.div`
    font-weight: 600;
`;

const GoldText = styled(BoldText)`
    color: orange;
`

const Line = styled.div`
 border-bottom: solid 1px #397FCC;
 margin-right: 1em;
`

export default Inbox


