import React, {Component} from 'react'
import {fetchConversations} from './../helpers/Api'
import './ConversationsList.css';
import Moment from 'react-moment';
import {Link} from "react-router-dom";
import AuthService from '../login/Auth'
import TopNav from "../helpers/TopNav";


export const auth = new AuthService();

class ConversationsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "Loading",
            conversationData: null,
        };
    }

    componentDidMount() {
        fetchConversations(auth.getToken())
            .then(data => {
                this.setState({status: "Fetch completed", conversationData: data})
            }).catch(_ => this.setState({status: "Error"}))
    }

    renderLoading() {
        switch (this.state.status) {
            case "Loading":
                return <p> Fetching, please wait </p>;
            case "Error":
                return <p> An error occurred fetching Conversations. Please try again later. </p>;
            default:
                return null
        }
    };

    render() {
        return (
            <div>
                <TopNav token={auth.getToken()} title="Conversations"/>
                {this.state.conversationData &&
                <div className="cards">
                    {this.state.conversationData.map((conversation, index) =>
                        <Link key={index} to={`/conversations/${conversation.id}`} className="card">
                            <div className="card-body">
                                <h6 className="card-title">{conversation.title}</h6>
                                <div className="card-text">
                                    <Moment format="MM/DD/YY">
                                        {conversation.date}
                                    </Moment></div>
                            </div>
                        </Link>
                    )}
                </div>
                }

                {this.state.conversationData && this.state.conversationData.length === 0 &&
                <p> You have no Conversations. Please contact us if you'd like to set some up.</p>
                }
                {this.renderLoading()}
            </div>
        )
    }
}

export default ConversationsList


