import React, {Component} from 'react'
import {
    fetchConversation
} from './../helpers/Api'
import Moment from 'react-moment';
import AuthService from "../login/Auth";
import './Conversation.css';
import {getInitials} from "../helpers/StringHelpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";
import AriaModal from "react-aria-modal";
import NewEmail from "../helpers/NewEmail";
import {Link} from "react-router-dom";

export const auth = new AuthService();


class Conversation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "Idle",
            conversationData: null,
            id: Number(this.props.match.params.id),
            showParticipant: null,
            emailModalActive: false,
        };

        this.participantQuotesRef = React.createRef();
        this.activateEmailModal = this.activateEmailModal.bind(this);
        this.deactivateEmailModal = this.deactivateEmailModal.bind(this);
        this.getApplicationNode = this.getApplicationNode.bind(this);


        this.setParticipant = this.setParticipant.bind(this)

    }

    componentDidMount() {
        this.setState({status: "Loading"});

        fetchConversation(auth.getToken(), this.state.id)
            .then(data => {
                this.setState({status: "Fetch completed", conversationData: data})
            }).catch(_ => this.setState({status: "Error"}));
    }

    activateEmailModal = () => {
        this.setState({emailModalActive: true});
    };

    deactivateEmailModal = () => {
        this.setState({emailModalActive: false})
    };

    getApplicationNode = () => {
        return document.getElementById('root');
    };

    setParticipant(person) {
        this.setState({showParticipant: person});
        if (this.participantQuotesRef.current) {
            this.participantQuotesRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
            })
        }

    }

    renderLoading() {
        switch (this.state.status) {
            case "Loading":
                return <p> Fetching, please wait </p>;
            case "Error":
                return <p> An error occurred fetching the Conversation. Please try again later. </p>;
            default:
                return null
        }
    };

    render() {
        return (
            <div>
                {this.state.conversationData &&
                <div>
                    <div className="title-area">
                        <nav className="navbar navbar-expand-lg">
                            <span className="nav-header">{this.state.conversationData.title}</span>
                        </nav>
                        <div className="summary">
                            <div className="summary-box">
                                <span className="summary-title">Meeting</span>
                                <div>
                                    <Moment format="MM/DD/YY">
                                        {this.state.conversationData.date}
                                    </Moment>
                                </div>
                            </div>
                            <div className="summary-box">
                                <span className="summary-title">Location</span>
                                <div>
                                    {this.state.conversationData.location}
                                </div>
                            </div>
                            {this.state.conversationData.report_link &&
                            <div className="summary-box">
                                <h6 className="summary-title">Insights Report</h6>
                                <div className="margin-bottom">
                                    <a href={this.state.conversationData.report_link} target="_blank"
                                       rel="noopener noreferrer"
                                       className="view-button button">View</a>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    {this.state.conversationData.key_insights[0] &&
                    <div>
                        <h6 className="singal-modal-content header margin-bottom">Key Insights and Next Steps</h6>
                        <ul className="flex-1 white-box margin-right">
                            {this.state.conversationData.key_insights.sort((a, b) => a.rank - b.rank).map((i, _) =>
                                <li className="d-flex margin-bottom" key={i.rank}>
                                    <div className="bubble">{i.rank}</div>
                                    <div>{i.insight}</div>
                                </li>
                            )}
                        </ul>
                    </div>

                    }
                    <div className="singal-modal-content">
                        <div className="flex-2">
                            <h6 className="header">Participants</h6>
                            {this.state.conversationData.participants &&
                            <div className="flex-row-container">
                                {this.state.conversationData.participants.map((person, _) =>
                                    <div id="participant-box" className="flex-row-item dashboard-box" key={person.id}
                                         onClick={() => this.setParticipant(person)}>
                                        <div className="large-bubble margin-bottom">{getInitials(person.name)}</div>
                                        <div className="margin-bottom">{person.name}</div>
                                    </div>
                                )}
                            </div>
                            }
                        </div>

                        {this.state.showParticipant &&
                        <div className="flex-1 white-box" ref={this.participantQuotesRef}>
                            <h2 onClick={() => this.setParticipant(null)} className="hover">
                                <FontAwesomeIcon icon="chevron-right"/>
                            </h2>
                            <div className="flex-row-item margin-bottom-3">
                                <Link to={`/participants/${this.state.showParticipant.id}`} className="flex-row-item">
                                    <div className="large-bubble margin-bottom">
                                        {getInitials(this.state.showParticipant.name)}
                                    </div>
                                    <h6 className="header">{this.state.showParticipant.name}</h6>
                                </Link>
                                {this.state.showParticipant.phone &&
                                <div>
                                    <div className="accent-text">{this.state.showParticipant.phone}</div>
                                </div>
                                }
                                {this.state.showParticipant.email &&
                                <div>
                                    <div className="accent-text margin-bottom">{this.state.showParticipant.email}</div>
                                    {this.state.showParticipant.email &&
                                    <div className="email-button button" onClick={this.activateEmailModal}>New
                                        Email</div>
                                    }
                                </div>
                                }
                            </div>

                            {this.state.showParticipant.quotes.length !== 0 &&
                            <h6 className="header">Quotes</h6>
                            }
                            {this.state.showParticipant.quotes.map((q, i) =>
                                <li key={i}>{q}</li>
                            )}
                            {this.state.emailModalActive &&
                            <AriaModal
                                titleText="New Email"
                                onExit={this.deactivateEmailModal}
                                initialFocus="#email-deactivate"
                                getApplicationNode={this.getApplicationNode}
                                underlayProps={{
                                    'data-foo': 'foo'
                                }}
                                underlayStyle={{
                                    background: 'rgba(74,20,140, 0.5)',
                                }}
                            >
                                <NewEmail close={this.deactivateEmailModal}
                                          to={this.state.showParticipant.email}
                                          name={this.state.showParticipant.name}
                                          participant_id={this.state.showParticipant.id}
                                          client_name={auth.getClientName()}
                                          client_email={auth.getUserEmail()}
                                          location={this.state.conversationData.location}
                                />
                            </AriaModal>
                            }
                        </div>

                        }

                    </div>
                </div>
                }
                {this.renderLoading()}
            </div>
        )
    }
}

export default Conversation


