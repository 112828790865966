import * as React from "react"
import {resetPassword} from '../helpers/Api'
import './ResetPassword.css'
import TopNav from "../helpers/TopNav";


export class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            confirmPassword: "",
            status: "",
            validationMessage: "",
            token: this.props.match.params.token
        };
        this.sendResetRequest = this.sendResetRequest.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        const {name, value} = event.target;
        this.setState({[name]: value});
    };


    validatePasswordMatch(pass, confirmation) {
        return pass !== confirmation ?
            'Passwords do not match' :
            undefined;

    }

    sendResetRequest(e) {
        e.preventDefault();
        const err = this.validatePasswordMatch(this.state.password, this.state.confirmPassword);
        if (err !== undefined) {
            this.setState({status: "InvalidInput"});
            this.setState({validationMessage: err});
            return;
        }
        this.setState({loading: true});
        resetPassword(this.state.token, this.state.password)
            .then((response) => {
                switch (response.status) {
                    case 200:
                        this.setState({status: "Success"});
                        this.props.history.push("/login");
                        break;
                    case 401:
                        this.setState({status: "InvalidToken"});
                        break;
                    default:
                        break;


                }

                this.setState({loading: false});
            });
    }


    renderStatus() {
        switch (this.state.status) {
            case "Loading":
                return <p> Sending, please wait </p>;
            case "Error":
                return <p className="error"> An error occurred resetting the password. Please try again later. </p>;
            case "InvalidToken":
                return <p className="error"> This reset password link has expired. Please request a new link</p>;
            case "InvalidInput":
                return <p className="error">{this.state.validationMessage}</p>
            default:
                return null
        }
    };

    render() {
        return (
            <div>
                <TopNav title="Reset Password"/>

                <form id="login">
                    <p>Enter your new password:</p>
                    <fieldset>
                        <div className="form-group">
                            <input className="form-control"
                                   placeholder="Password"
                                   name="password"
                                   autoComplete="password"
                                   type="password"
                                   onChange={this.onChange}
                                   value={this.state.password}
                            />
                        </div>
                        <p>Confirm your new password:</p>
                        <div className="form-group">
                            <input className="form-control"
                                   placeholder="Confirm Password"
                                   name="confirmPassword"
                                   autoComplete="confirmPassword"
                                   type="password"
                                   onChange={this.onChange}
                                   value={this.state.confirmPassword}
                            />
                        </div>
                        <button className="btn btn-lg btn-block"
                                onClick={this.sendResetRequest}
                        >Submit
                        </button>
                    </fieldset>
                </form>
                <div className="status">
                    {this.renderStatus()}
                </div>
            </div>
        )
    }
}

export default ResetPassword
