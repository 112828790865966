import * as React from "react"
import {auth} from '../App'
import {getUser, login} from '../helpers/Api'
import TopNav from "../helpers/TopNav";
import {Link} from "react-router-dom";


export class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {email: "", password: "", loggedIn: false, loading: false, loginFailed: false};
        this.doLogin = this.doLogin.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        const {name, value} = event.target;
        this.setState({[name]: value});
    };

    async doLogin(e) {
        e.preventDefault();
        this.setState({loading: true, loginFailed: false});
        await login(this.state.email, this.state.password)
            .then((response) => {
                const success = response.status === 201;
                if (success) {
                    auth.setToken(response.token);
                    this.setState({loading: false, loggedIn: true});
                } else {
                    this.setState({loading: false, loginFailed: true});
                }
            });
        if (this.state.loggedIn) {
            await getUser(auth.getToken()).then((r) => {
                auth.setUser(r)
            });
            this.props.setLoginState(true);
            this.props.history.push("/home");

        }
    }

    render() {

        return (
            <div>
                <TopNav title="Login"/>
                <form id="login">
                    <fieldset>
                        <div className="form-group">
                            <input className="form-control"
                                   placeholder="E-mail"
                                   name="email"
                                   autoComplete="email"
                                   type="text"
                                   onChange={this.onChange}
                                   value={this.state.email}
                                   autoFocus
                            />
                        </div>
                        <div className="form-group">
                            <input className="form-control"
                                   placeholder="Password"
                                   name="password"
                                   autoComplete="password"
                                   type="password"
                                   onChange={this.onChange}
                                   value={this.state.password}
                            />
                        </div>
                        <Link to="/forgot_password">Forgot Password</Link>
                        <button className="btn btn-lg btn-success btn-block"
                                onClick={this.doLogin}
                        >Login
                        </button>
                    </fieldset>
                </form>
                <div>{this.state.loading ? "Authenticating please wait" : ""}</div>
                <div>{this.state.loginFailed ? "Email and Password combination invalid" : ""}</div>
                <div>{this.state.loggedIn ? "Logged in Successfully" : ""}</div>
            </div>
        )
    }
}

export default LoginPage
