import React, {Component} from 'react'
import styled from 'styled-components'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";
import {sendEmail} from '../helpers/Api'
import AuthService from "../login/Auth";

export const auth = new AuthService();

class NewEmail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            to: this.props.to,
            participant_id: this.props.participant_id,
            from: this.props.client_email,
            subject: "Thank you",
            emailBody: `Hi ${this.props.name},
            Thank you again for joining us in our conversation a few weeks ago at the ${this.props.location || "[insert location]"}! It was invaluable for me to learn your stories and get your ideas for how ${this.props.client_name} can improve. There is always room to be better, and we are committed to listening to and learning from our community. \n
[Optional: In response to the issue you raised re: [insert specific issue], I have looked into it and [insert response to their issue]. \n
I am grateful to have met you and that you took the time to share your ideas and feedback with us. \n
Please continue to be in touch with us at ${this.props.client_email}, and always feel free to let us know how you think we can improve the work we're doing in our community!\n
Sincerely,\n\n`,
            status: null,
        };
        this.handleSubject = this.handleSubject.bind(this);
        this.handleEmailBody = this.handleEmailBody.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubject(event) {
        this.setState({subject: event.target.value});
    }

    handleEmailBody(event) {
        this.setState({emailBody: event.target.value});
    }

    handleSubmit(event) {
        sendEmail(this.state.participant_id, this.state.subject, this.state.emailBody, auth.getToken())
            .then((response) => {
                switch (response.status) {
                    case 200:
                        this.setState({status: "Email Success"});
                        break;
                    case 400:
                        this.setState({status: "Email Fail"});
                        break;
                    default:
                        break;


                }
            });
        event.preventDefault();

        setTimeout(function () {
            this.props.close();
        }.bind(this), 1500)
    }

    renderStatus() {
        switch (this.state.status) {
            case "Email Fail":
                return <p className="error"> An error occurred sending the email. Please try again later </p>;
            case "Email Success":
                return <p>Email was sent successfully.</p>;
            default:
                return null
        }
    };


    render() {
        return (
            <EmailForm onSubmit={this.handleSubmit}>
                <ModalSection>
                    <h2 id="email-deactivate" onClick={this.props.close} className="hover">
                        <FontAwesomeIcon icon="times-circle"/>
                    </h2>
                </ModalSection>
                <EmailHeader>
                        <div>To: <span className="accent-text">{this.state.to}</span></div>
                        <div>From: <span className="accent-text">{this.state.from}</span></div>
                        <div>Subject:<input type="text" value={this.state.subject} onChange={this.handleSubject}/></div>
                </EmailHeader>
                <ModalSection>
                    <EmailBody type="text" value={this.state.emailBody} onChange={this.handleEmailBody}/>
                </ModalSection>
                <ModalSection>
                    <footer className="modal-footer">
                        <input type="submit" className="email-button button" value="Send"/>
                    </footer>
                </ModalSection>
                <div className="status">
                    {this.renderStatus()}
                </div>
            </EmailForm>
        )
    }
}

const EmailBody = styled.textarea`
  flex: 1;
  height: 15em;
`;

const ModalSection = styled.section`
    display: flex;
    padding: 1em;
`;

const EmailHeader = styled(ModalSection)`
    background: #F6F6F6;
    color: #9B9B9B;
    flex-direction: column;
`;

const EmailForm = styled.form`
    background: white;
    border-radius: .5em;
    margin: 3em;
    padding: 0;
    min-width: 40em;
    -webkit-box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.35);
`;

export default NewEmail
