import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {NavLink} from 'react-router-dom';
import logo from '../logo.png';

import './Sidebar.css';


class Sidebar extends Component {
    render() {
        return (
            <nav id="sidebar" className="active">
                <div className="sidebar-content">
                    <div className="sidebar-header">
                        <img src={logo} className="app-logo" alt="logo"/>
                    </div>

                    {this.props.loggedIn &&
                    <ul className="list-unstyled components">
                        <li className="sidebar-item">
                            <NavLink to="/" activeClassName="active" exact className="sidebar-link"><FontAwesomeIcon
                                icon="tachometer-alt"
                            />Dashboard</NavLink>
                        </li>
                        <li className="sidebar-item">
                            <NavLink to="/boards" activeClassName="active" className="sidebar-link"><FontAwesomeIcon
                                icon="comment-alt"
                            />Advisory Boards</NavLink>
                        </li>
                        <li className="sidebar-item">
                            <NavLink to="/conversations" activeClassName="active" className="sidebar-link"><FontAwesomeIcon
                                icon="clipboard-list"
                            />Conversations</NavLink>
                        </li>
                        <li className="bottom sidebar-item">
                            <button className="button sidebar-link" onClick={this.props.logout}><FontAwesomeIcon
                                icon="sign-out-alt"
                            />Sign Out
                            </button>
                        </li>
                    </ul>
                    }
                </div>
            </nav>
        )
    }
}

export default Sidebar
