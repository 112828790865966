import React, {Component} from 'react'
import {fetchConversations, fetchAdvisoryBoards} from './../helpers/Api'
import './Dashboard.css';
import AuthService from '../login/Auth'
import TopNav from "../helpers/TopNav";
import Moment from 'moment';
import MomentComponent from 'react-moment';
import {Link} from "react-router-dom";


export const auth = new AuthService();

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "Loading",
            futureConversations: null,
            pastConversations: null,
            advisoryBoards: null,
        };
    }

    componentDidMount() {
        fetchConversations(auth.getToken())
            .then(data => {
                const futureConv = data.filter(conv => Moment(conv.date).isSameOrAfter(Moment(), "day"));
                const pastConv = data.filter(conv => Moment(conv.date).isBefore(Moment(), "day"));
                this.setState({status: "Success", futureConversations: futureConv, pastConversations: pastConv})
            }).catch(_ => this.setState({status: "Error"}));
        fetchAdvisoryBoards(auth.getToken())
            .then(data => {
                this.setState({advisoryBoards: data})
            }).catch(_ => this.setState({status: "Error"}))
    }

    renderLoading() {
        switch (this.state.status) {
            case "Loading":
                return <p> Fetching, please wait </p>;
            case "Error":
                return <p> An error occurred loading the Dashboard Data. Please try again later. </p>;
            default:
                return null
        }
    };

    static displayCount(count) {
        if(count) {
            return String(count.length).padStart(2, 0)
        }
        return "00"
    }

    render() {
        return (
            <div>
                <TopNav token={auth.getToken()} title="Dashboard"/>
                {this.state.status === "Success" &&
                <div>
                    <div className="summary">
                        <div className="summary-box">
                            <div className="summary-title">Upcoming Conversations</div>
                            <div
                                className="summary-count">{Dashboard.displayCount(this.state.futureConversations)}</div>
                        </div>
                        <div className="summary-box">
                            <div className="summary-title">Past Conversations</div>
                            <div
                                className="summary-count">{Dashboard.displayCount(this.state.pastConversations)}</div>
                        </div>
                        < div className="summary-box">
                            <div className="summary-title">Advisory Boards</div>
                            <div
                                className="summary-count">{Dashboard.displayCount(this.state.advisoryBoards)}
                            </div>
                        </div>
                    </div>
                    <div className="summary">
                        <div className="dashboard-box">
                            <div className="title">Upcoming Conversations</div>
                            <table>
                                <tbody>
                                {this.state.futureConversations
                                    .sort((a,b) => Moment(a.date).isBefore(Moment(b.date))? -1 : 1)
                                    .slice(0, 5)
                                    .map(function (conv) {
                                    return <tr key={conv.id}>
                                        <td>
                                            <Link to={`/conversations/${conv.id}`}
                                                  className="dashboard-conv">{conv.title}</Link>
                                        </td>
                                        <td className="purple-text"><MomentComponent
                                            format="MM/DD/YY">{conv.date}</MomentComponent></td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                            <div>
                                {this.state.futureConversations.length > 5 &&
                                <div className="purple-text">+ more</div>
                                }
                            </div>
                        </div>
                        <div className="dashboard-box">
                            <Link to={`/conversations`} className="title">Past Conversations</Link>
                            <table>
                                <tbody>
                                {this.state.pastConversations
                                    .sort((a,b) => Moment(a.date).isAfter(Moment(b.date), "day")? -1 : 1)
                                    .slice(0, 5)
                                    .map(function (conv) {                                    return <tr key={conv.id}>
                                        <td>
                                            <Link to={`/conversations/${conv.id}`}
                                                  className="dashboard-conv">{conv.title}</Link>
                                        </td>
                                        <td className="purple-text"><MomentComponent
                                            format="MM/DD/YY">{conv.date}</MomentComponent></td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                            <div>
                                {this.state.pastConversations.length > 5 &&
                                    <div className="purple-text">+ more</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                }
                {this.state.advisoryBoards &&
                <div className="flex-row-container">
                    {this.state.advisoryBoards.map(function (board) {
                        return <Link to={`/advisory_board/${board.id}`}
                                     className="dashboard-box title flex-row-item" key={board.id}>{board.name}</Link>
                    })}
                </div>
                }
                {this.renderLoading()}
            </div>
        )
    }
}

export default Dashboard


