export default class AuthService {

    setToken(token) {
        localStorage.setItem('token', token);
        let date = new Date();
        date.setDate(date.getDate() + 6);
        localStorage.setItem('token_expiration', date)
    }

    setUser(user) {
        localStorage.setItem('user_email', user.email);
        localStorage.setItem('user_id', user.id);
        localStorage.setItem('user_client_name', user.client_name);
    }

    getClientName() {
        return localStorage.getItem('user_client_name') || ""
    }

    getUserEmail() {
        return localStorage.getItem('user_email') || ""
    }

    loggedIn() {
        const token_not_expired = new Date(localStorage.getItem('token_expiration')) >= new Date();
        return token_not_expired && !!this.getToken()
    }

    getToken() {
        return localStorage.getItem('token')
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('token_expiration');
        localStorage.removeItem('user_email');
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_client_name');
        window.location.replace('/');
    }
}
