import React, {Component, Fragment} from 'react';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import './App.css';

import AdvisoryBoard from './client/AdvisoryBoard'
import AdvisoryBoardList from "./client/AdvisoryBoardList";
import AuthService from './login/Auth'
import Conversation from './client/Conversation'
import ConversationsList from './client/ConversationsList'
import Dashboard from './client/Dashboard'
import ForgotPassword from './login/ForgotPassword'
import Inbox from "./client/Inbox";
import LoginPage from './login/LoginPage'
import Participants from './client/Participant'
import ResetPassword from './login/ResetPassword'

import Sidebar from "./helpers/Sidebar";

import axios from 'axios'
import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faBars,
    faCommentAlt,
    faSignOutAlt,
    faUser,
    faBell,
    faTachometerAlt,
    faClipboardList,
    faChevronRight,
    faTimesCircle
} from '@fortawesome/free-solid-svg-icons';


library.add(faBars, faCommentAlt, faSignOutAlt, faUser, faBell, faTachometerAlt, faClipboardList, faChevronRight, faTimesCircle);


export const auth = new AuthService();
export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 5000
});

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: auth.loggedIn(),
        };
        this.logout = this.logout.bind(this)
        this.setLoggedInState = this.setLoggedInState.bind(this)
    }

    setLoggedInState(loggedIn) {
        this.setState({isLoggedIn: loggedIn})
    }


    logout() {
        auth.logout();
        this.forceUpdate()
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Fragment>
                        <div className="appBox">
                            <Sidebar className="column" logout={this.logout} loggedIn={this.state.isLoggedIn}/>
                            <div className="content column">
                                {this.state.isLoggedIn &&
                                <div>
                                    <Route exact={true} path="/" component={Dashboard}/>
                                    <Route path="/login" render={(props) => <LoginPage {...props}
                                                                                       setLoginState={this.setLoggedInState}/>}/>
                                    <Route path="/home" component={Dashboard}/>
                                    <Route exact={true} path="/conversations" component={ConversationsList}/>
                                    <Route exact={true} path="/conversations/:id" component={Conversation}/>
                                    <Route path="/participants/:id" component={Participants}/>
                                    <Route path="/advisory_board/:id" component={AdvisoryBoard}/>
                                    <Route path="/boards" component={AdvisoryBoardList}/>
                                    <Route path="/inbox" component={Inbox}/>
                                </div>
                                }

                                {!this.state.isLoggedIn &&
                                <div>
                                    <Route path="/login" render={(props) => <LoginPage {...props}
                                                                                       setLoginState={this.setLoggedInState}/>}/>
                                    <Route exact path="/" render={() => <Redirect to="/login"/>}/>
                                </div>
                                }
                                <Route path="/reset/:token" component={ResetPassword}/>
                                <Route path="/forgot_password" component={ForgotPassword}/>

                            </div>
                        </div>
                    </Fragment>
                </Switch>
            </BrowserRouter>

        );
    }
}

export default App;
