import * as React from "react"
import {forgotPassword} from '../helpers/Api'
import TopNav from "../helpers/TopNav";


export class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            status: "",
        };
        this.sendRequest = this.sendRequest.bind(this);
        this.onChange = this.onChange.bind(this);
        this.renderStatus = this.renderStatus.bind(this);
    }

    onChange(event) {
        const {name, value} = event.target;
        this.setState({[name]: value});
    };

    sendRequest(e) {
        e.preventDefault();
        this.setState({status: "Loading"});
        forgotPassword(this.state.email)
            .then((response) => {
                switch (response.status) {
                    case 200:
                        this.setState({status: "Success"});
                        break;
                    case 404:
                        this.setState({status: "Error"});
                        break;
                    default:
                        break;


                }
            });
    }


    renderStatus() {
        switch (this.state.status) {
            case "Loading":
                return <p> Sending, please wait </p>;
            case "Error":
                return <p className="error"> An error occurred generating a reset email. Please try again later </p>;
            case "Success":
                return <p>An email has been sent. Please check your inbox for a link to generate a new password.</p>
            default:
                return null
        }
    };

    render() {
        return (
            <div>
                <TopNav title="Reset Password"/>

                <form id="login">
                    <p>Enter your email address:</p>
                    <fieldset>
                        <div className="form-group">
                            <input className="form-control"
                                   placeholder="Email"
                                   name="email"
                                   autoComplete="email"
                                   type="text"
                                   onChange={this.onChange}
                                   value={this.state.email}
                            />
                        </div>
                        <button className="btn btn-lg btn-block"
                                onClick={this.sendRequest}
                        >Submit
                        </button>
                    </fieldset>
                </form>
                <div className="status">
                    {this.renderStatus()}
                </div>
            </div>
        )
    }
}

export default ForgotPassword
