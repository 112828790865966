import {api} from '../App'

export function fetchClients(token) {
    return api.get('/client', {headers: {'Authorization': 'Bearer ' + token}})
        .then(function (response) {
            return response.data
        })
}

export function fetchConversations(token) {
    return api.get('/conversation', {headers: {'Authorization': 'Bearer ' + token}})
        .then(function (response) {
            return response.data
        })
}

export function fetchAdvisoryBoards(token) {
    return api.get('/advisory_board', {headers: {'Authorization': 'Bearer ' + token}})
        .then(function (response) {
            return response.data
        })
}


export function fetchAdvisoryBoard(token, id) {
    return api.get('/advisory_board/' + id, {headers: {'Authorization': 'Bearer ' + token}})
        .then(function (response) {
            return response.data
        })
}

export function fetchConversation(token, id) {
    return api.get('/conversation/' + id, {headers: {'Authorization': 'Bearer ' + token}})
        .then(function (response) {
            return response.data
        })
}


export function fetchParticipant(token, id) {
    return api.get('/participant/' + id, {headers: {'Authorization': 'Bearer ' + token}})
        .then(function (response) {
            return response.data
        })
}

export function login(email, password) {
    return api.post('/user_token', {'auth': {email: email, password: password}})
        .then(function (response) {
            return {status: response.status, token: response.data['jwt']}
        })
        .catch(function (error) {
            return {message: error, status: 401}
        })
}

export function getUser(token) {
    return api.get('/user_info', {headers: {'Authorization': 'Bearer ' + token}})
        .then(function (response) {
            return response.data
        })
}

export function resetPassword(token, password) {
    return api.post('/reset_password', {'token': token, 'newPassword': password})
        .then(function (response) {
            return {status: response.status, message: 'Success'}
        })
        .catch(function (error) {
            return {message: error, status: 401}
        })
}

export function forgotPassword(email) {
    return api.put('/forgot_password', {'email': email})
        .then(function (response) {
            return {status: response.status, message: 'Success'}
        })
        .catch(function (error) {
            return {message: error, status: 404}
        })
}

export function sendEmail(participant_id, subject, body, token) {

    return api.post('/email',
        {'participant_id': participant_id, 'content': body, 'subject': subject},
        {headers: {'Authorization': 'Bearer ' + token}}
    )
        .then(function (response) {
            return {status: response.status, message: 'Success'}
        })
        .catch(function (error) {
            return {message: error, status: 400}
        })
}


export function fetchEmails(token) {
    return api.get('/email', {headers: {'Authorization': 'Bearer ' + token}})
        .then(function (response) {
            return response.data
        })
}